<template>
    <!-- <div></div> -->
    <div class="bottom">
        <div class="connect">
            <div class="connect-l">
                <span>商务洽谈：</span>
                <img src="../static/phone.png" class="phone" />
                <span style="margin-right: 13px;">{{cfg.sw}}</span>
                <span>(</span>
                <img src="../static/weixin.png" class="weixin" />
                <span style="letter-spacing: 1px;">微信同)</span>
            </div>
            <div class="conn"></div>
            <div class="connect-r">
                <span>技术支持：</span>
                <img src="../static/zu.png" class="zu" />
                <span style="margin-right: 13px;">{{cfg.js}}</span>
            </div>
        </div>
        <a href="https://beian.miit.gov.cn" target="parent">
            <span class="bottomContainer">
                {{cfg.icp1}} &nbsp;&nbsp;&nbsp;&nbsp;  联网备案号：{{cfg.icp2}}
            </span>
        </a>
    </div>
</template>

<script>
export default {
    name: 'footerModule',
    inject: ['cfgData'],
    computed:{
        cfg(){
            return this.cfgData()
        }
    }
}
</script>

<style scoped>

.bottom{
    width: 100%;
    height: 12.9vh;
    background: #F8F9FA;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.connect{
    display: flex;
    align-items: center;
}
.connect-l, .connect-r{
    display: flex;
    align-items: center;
}
.phone{
    width: 18px;
    height: 33px;
    margin-right: 13px;
}
.weixin{
    width: 14px;
    height: 11px;
    margin: 0 2px;
}
.conn{
    width: 1px;
    height: 38px;
    background: #ccc;
    margin: 0 35px;
}
.zu{
    width: 24px;
    height: 29px;
    margin-right: 13px;
}
.bottom a{
    color: #9A9A9B;
    text-decoration: none;
    margin-top: 1.8vh;
}
</style>