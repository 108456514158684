<template>
    <transition name="el-fade-in-linear">
        <div class="modal" v-if="isShow">
            <div class="cfg" v-loading="loading">
                <div class="header">
                    <div class="header-l">
                        <img src="../static/avatar.png" />
                        <span v-if="isLogin">{{timeState}} {{phone}}</span>
                        <span v-else>未登录</span>
                    </div>
                    <div class="header-r">
                        <div @click="submitFrom" v-show="isLogin">保存</div>
                        <div @click="cancel">取消</div>
                    </div>
                </div>
                <div class="content">
                    <div class="mengceng" v-if="!isLogin"></div>
                    <!-- 第一行 -->
                    <div class="row">
                        <div class="item-upload">
                            <div class="upload">
                                <el-upload
                                    class="upload-demo"
                                    drag
                                    :action="uploadUrl"
                                    :data="{ type: 1 }"
                                    :on-success="onSuccess"
                                    :on-remove="onRemove"
                                    v-if="!exts.logo"
                                >
                                    <i class="el-icon-upload"></i>
                                    <div class="el-upload__text">将文件拖至此处<br/>或<em>点击上传</em></div>
                                    <div class="el-upload-desc">( 支持png格式 )</div>
                                </el-upload>
                                <!-- 有图片时 -->
                                <div class="img-box" v-else>
                                    <img :src="exts.logo" />
                                    <i class="el-icon-circle-close" @click="onRemove"></i>
                                </div>
                                <!-- 有图片时 -->
                            </div>
                        </div>
                        <div class="item-box">
                            <div class="item">
                                <span>公司名称：</span>
                                <input v-model="exts.companyName" type="text" placeholder="请输入公司名称" />
                            </div>
                            <div class="item">
                                <span>公司简称：</span>
                                <input v-model="exts.companyName2" type="text" placeholder="请输入公司简称" />
                            </div>
                            <div class="item">
                                <span>技术支持：</span>
                                <input v-model="exts.js" type="text" placeholder="请输入技术支持" />
                            </div>
                        </div>
                        <div class="item-box">
                            <div class="item">
                                <span>二次开发域名：</span>
                                <input v-model="url" type="text" placeholder="请输入二次开发域名" />
                            </div>
                            <div class="item">
                                <span class="require-label">GPS平台IP：</span>
                                <input v-model="ip" type="text" placeholder="请输入GPS平台IP" />
                            </div>
                            <div class="item">
                                <span>商务洽谈：</span>
                                <input v-model="exts.sw" type="text" placeholder="请输入商务洽谈" />
                            </div>
                        </div>
                    </div>
                    <!-- 第一行 -->
                    <!-- 第二行 -->
                    <div class="row">
                        <div class="item-box" style="max-width: 230px;">
                            <div class="item">
                                <span>模拟请求IP：</span>
                                <input v-model="exts.ip" type="text" placeholder="模拟请求(IP:端口)" style="max-width: 120px" />
                            </div>
                        </div>
                        <div class="item-box">
                            <div class="item" style="max-width: 220px;">
                                <span>联网备案号：</span>
                                <input v-model="exts.icp2" type="text" placeholder="请输入联网备案号" style="max-width: 120px" />
                            </div>
                        </div>
                        <div class="item-box">
                            <div class="item">
                                <span>ICP备案/许可证号：</span>
                                <input v-model="exts.icp1" type="text" placeholder="请输入ICP备案/许可证号" />
                            </div>
                        </div>
                    </div>
                    <!-- 第二行 -->
                    <!-- 第三行 -->
                    <div class="row2">
                        <div class="row3" style="margin-right: 20px;">
                            <div class="row-l">
                                <div class="item-box">
                                    <div class="item">
                                        <span>负责人1：</span>
                                        <input v-model="exts.rPerson1" type="text" placeholder="请输入负责人1" />
                                    </div>
                                    <div class="item">
                                        <span>负责人2：</span>
                                        <input v-model="exts.rPerson2" type="text" placeholder="请输入负责人2" />
                                    </div>
                                    <div class="item">
                                        <span>负责人3：</span>
                                        <input v-model="exts.rPerson3" type="text" placeholder="请输入负责人3" />
                                    </div>
                                </div>
                            </div>
                            <div class="row-r">
                                <div class="item-box">
                                    <div class="item">
                                        <span>负责人4：</span>
                                        <input v-model="exts.rPerson4" type="text" placeholder="请输入负责人4" />
                                    </div>
                                    <div class="item">
                                        <span>负责人5：</span>
                                        <input v-model="exts.rPerson5" type="text" placeholder="请输入负责人5" />
                                    </div>
                                    <div class="item">
                                        <span>负责人6：</span>
                                        <input v-model="exts.rPerson6" type="text" placeholder="请输入负责人6" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row3 wrap">
                            <div v-for="item in lists" :key="item.url" :class="['nav', checkedLists.includes(item.url) && 'nav-act']" @click="handleNav(item.url)">
                                {{item.name}}
                                <img src="../static/gou.png" />
                            </div>
                            <div :class="['nav', exts.radio == 1 && 'nav-act']" @click="handleWx">
                                微信反馈
                                <img src="../static/gou.png" />
                            </div>
                            <div class="nav" style="opacity:0"></div>
                        </div>
                    </div>
                    <!-- 第三行 -->
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { baseUrl } from '@/http/index'
import { getUrlParams, getTimeState } from "@/utils/common"
export default {
    name: 'cfgIndex',
    data() {
        return {
            isLogin: false,
            isShow: false,
            loading: false,
            uploadUrl: baseUrl + '/apiDocUpload/uploadFile',
            timeState: getTimeState(),
            phone: '',
            url: '',
            ip: '',
            id: '',
            exts: {
                logo: '',
                radio: 2,
                companyName: '',
                sw: '',
                js: '',
                ip: '',
                rPerson1: '',
                rPerson2: '',
                rPerson3: '',
                rPerson4: '',
                rPerson5: '',
                rPerson6: ''
            },
            checkedLists: [],
            lists: [
                {name: '首页', url: '/index'},
                {name: '接口文档', url: '/api'},
                {name: '设备扩展', url: '/device'},
                {name: '代码开源', url: '/code'},
                {name: '视频讲座', url: '/video'},
                {name: '场景分析', url: '/scene'},
                {name: '三方工具', url: '/tool'}
            ]
        }
    },
    watch: {
        ip(val) {
            if(val == '') {
                return this.exts.ip = ''
            } else {
                return this.exts.ip = val + ':9999'
            }
        }
    },
    mounted() {
        this.phone = getUrlParams(location.hash).phone
        if(this.phone) {
            this.isShow = true
            this.getCheck()
        }
    },
    methods: {
        handleWx() {
            this.exts.radio = this.exts.radio == 1 ? 2 : 1
        },
        onSuccess(e) {
            if(e.status == 1) {
                this.exts.logo = e.result
            }
        },
        onRemove() {
            this.exts.logo = ''
        },
        cancel() {
            this.isShow = false
        },
        handleNav(url) {
            const index = this.checkedLists.findIndex(item => item === url)
            if(index == -1) {
                this.checkedLists.push(url)
            } else {
                this.checkedLists.splice(index, 1)
            }
        },
        async getCheck() {
            const ret = await this.$Http('GET', { phone: this.phone }, '/api/apiCfg/checkIdentityWeb', false);
            this.pageLoading = false
            if(ret.data.status == 1) {
                this.isLogin = true
                this.getCfg()
            } else {
                this.$message.error(ret.data.result)
            }
        },
        async getCfg() {
            const ret = await this.$Http('GET', { phone: this.phone }, '/api/apiCfg/getCfgByPhone');
            this.id = ret.id
            this.url = ret.url
            this.ip = ret.ip
            if(ret.exts) {
                this.exts = JSON.parse(ret.exts)
                this.fileList = [{
                name: 'logo',
                url: this.exts.logo                        
                }]
                if(!this.exts.checkedLists) {
                this.checkedLists = ['/index', '/api', '/develop', '/device', '/code', '/video', '/scene']
                } else {
                this.checkedLists = this.exts.checkedLists
                }
            } else {
                this.checkedLists = ['/index', '/api', '/develop', '/device', '/code', '/video', '/scene']
            }
        },
        async submitFrom() {
            if(this.ip == '') {
                return this.$message.warning("GPS平台IP不能为空！")
            }
            this.loading = true
            this.exts.checkedLists = this.checkedLists
            const params = {
                phone:this.phone,
                url:this.url,
                ip:this.ip,
                exts:JSON.stringify(this.exts)
            }
            if(this.id) {
                params.id = this.id
            }
            try {
                await this.$Http('POST', params, '/api/apiCfg/addOrUpdateCfg');
                this.$message.success("保存成功！")
                this.loading = false
            } catch {
                this.loading = false
            }
        }
    }
}
</script>

<style scoped>
.modal{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
}
.cfg{
    position: fixed;
    width: 950px;
    top: 50%;
    left: 50%;
    padding: 20px 35px;
    transform: translate(-50%, -50%);
    background: #F5FBFF;
    border-radius: 20px;
    box-sizing: border-box;
}
.header{
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header-l, .header-r{
    display: flex;
    align-items: center;
}
.header-l img{
    width: 50px;
    height: 50px;
    margin-right: 20px;
}
.header-r div{
    width: 70px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    font-size: 14px;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.03);
    border-radius: 10px;
    cursor: pointer;
}
.header-r div:first-child{
    color: #fff;
    background: #4289DA;
    margin-right: 30px;
}
.content{
    position: relative;
}
.mengceng{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    z-index: 2;
}
.row{
    padding: 20px;
    display: flex;
    background: #fff;
    margin-bottom: 20px;
}
.upload{
    width: 180px;
    height: 180px;
}
.upload>>>.upload-demo,
.upload>>>.el-upload,
.upload>>>.el-upload-dragger{
    width: 100%;
    height: 100%;
}
.upload>>>.el-upload-dragger .el-icon-upload{
    margin-top: 36px;
    margin-bottom: 12px;
}
.upload>>>.el-icon-upload:before{
    content: '';
    display: block;
    width: 54px;
    height: 48px;
    background: url('../static/upload.png') 100% 100% no-repeat;
    background-size: cover;
}
.upload>>>.el-upload-dragger .el-upload__text{
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
}
.el-upload-desc{
    font-size: 12px;
    color: #B6B6B6;
    transform: scale(0.9);
    margin-top: 8px;
}
.img-box{
    width: 100%;
    height: 100%;
    position: relative;
}
.img-box img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.el-icon-circle-close{
    font-size: 30px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}
.item-box{
    flex: 1;
    margin-left: 10px;
}
.item{
    height: 50px;
    padding: 0 20px;
    font-size: 14px;
    color: #333333;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    border-radius: 5px;
    margin-top: 12px;
    display: flex;
    align-items: center;
}
.item-box .item:first-child{
    margin-top: 0;
}
.item span{
    white-space: nowrap;
}
.item input{
    flex: 1;
}
.require-label::before{
    content: '*';
    color: red;
    margin-right: 2px;
}
.row2{
    display: flex;
}
.row3{
    padding: 20px;
    background: #fff;
    border-radius: 20px;
    display: flex;
}
.row3 .item-box{
    max-width: 220px;
}
.row3 input{
    max-width: 120px;
}
.wrap{
    justify-content: space-between;
    flex-wrap: wrap;
}
.nav{
    width: 90px;
    height: 50px;
    font-size: 14px;
    color: #B6B6B6;
    line-height: 50px;
    text-align: center;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
}
.nav-act{
    background: #E6F1FF;
    color: #333333;
}
.nav img{
    position: absolute;
    width: 16px;
    height: 16px;
    top: -6px;
    right: -6px;
    display: none;   
}
.nav-act img{
    display: block;
}
</style>