/* eslint-disable */
let source = null;

export const creatSSE = (callback, callbackServe) => {
    if (window.EventSource) {

        // 建立连接
        source = new EventSource('https://zs.31gps.net:8888/api/sse/subscribe');
        /**
         * 连接一旦建立，就会触发open事件
         * 另一种写法：source.onopen = function (event) {}
         */
        source.addEventListener('open', function (e) {
            callback()
        }, false);
    
        /**
         * 客户端收到服务器发来的数据
         * 另一种写法：source.onmessage = function (event) {}
         */
        source.addEventListener('message', function (e) {
            callbackServe(e)
        });
    
    
        /**
         * 如果发生通信错误（比如连接中断），就会触发error事件
         * 或者：
         * 另一种写法：source.onerror = function (event) {}
         */
        source.addEventListener('error', function (e) {
            if (e.readyState === EventSource.CLOSED) {
                console.log('close...');
            } else if (e.target.readyState === EventSource.CONNECTING) {
                console.log('Connecting...');
            } else {
                console.log(e);
            }
        }, false);
    
    } else {
        setMessageInnerHTML("你的浏览器不支持SSE");
    }
    
    // 监听窗口关闭事件，主动去关闭sse连接，如果服务端设置永不过期，浏览器关闭后手动清理服务端数据
    window.onbeforeunload = function () {
        
    };
}