<template>
    <transition name="el-fade-in-linear">
        <div :class="['card-box', aniAct && 'card-act2']" v-show="cardShow" @click="handleHide">
            <div class="cont" @click.stop>
                <div class="contBefore">
                    <img :src="require('../static/m/m' + (cardIndex + 1) +'.png')"  class="m1" />
                </div>
                <div class="contAfter">
                    <div class="card">
                        <img src="../static/card-d.png" class="card-d" />
                        <img src="../static/card-u.png" class="card-u" />
                        <img :src="require('../static/indexIcon/' + (cardIndex + 1) +'.png')"  class="icon" />
                        <div class="desc">
                            <p>{{cardData.name || ''}}</p>
                            <p>{{cardData.desc || ''}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
  name: "cardMoudle",
  props: {
    cardShow: Boolean,
    cardIndex: Number,
    cardData: Object
  },
  data() {
    return {
        aniAct: false
    }
  },
  watch: {
    cardShow(val) {
        setTimeout(() => {
            this.aniAct = val
        }, 100)
    }
  },
  methods: {
    handleHide() {
        this.$parent.cardShow = false
    }
  }
};
</script>

<style scoped>
.card-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
}
.cont {
    width: 610px;
    height: 408px;
    position: relative;
    color: #fff;
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cont .contBefore {
    width: 258px;
    height: 258px;
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
}
.m1{
    width: 258px;
    height: 258px;
}
.cont .contAfter {
    width: 610px;
    height: 200px;
    position: absolute;
    top: 0;
    left: 0; /*2.使用rotate翻转,背面初始翻转180*/
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg); /* 隐藏所翻转的元素*/
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
}
.card{
    position: relative; 
}
.card-d{
    position: absolute;
    top: 71px;
    width: 610px;
    height: 337px;
    z-index: 2;
}
.card-u{
    position: absolute;
    top: 0;
    left: 22px;
    width: 566px;
    height: 305px;
}
.card-act2 .contAfter {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
}
.card-act2 .contBefore {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}
.icon{
    position: absolute;
    z-index: 2;
    width: 82px;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
}
.desc{
    position: absolute;
    z-index: 2;
    width: 100%;
    top: 167px;
    padding: 0 95px;
    box-sizing: border-box;
}
.desc p:first-child{
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    line-height: 40px;
    text-align: center;
}
.desc p:last-child{
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    line-height: 40px;
    margin-top: 20px;
}
</style>