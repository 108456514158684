import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '../assets/theme.css'
import Http from '../http/index.js'
import resetMessage from '@/utils/resetMessage.js'
// 全局引入
import 'mv-full-page/lib-dist/mv-full-page.css'
import MvFullPage from 'mv-full-page'
import JsonViewer from 'vue-json-viewer';
import footerModule from './components/footer.vue'
// 引入 highlight.js 代码高亮工具
import hljs from "highlight.js";
// 使用样式，有多种样式可选
import "highlight.js/styles/github-dark.css";
// 增加自定义命令v-highlight
Vue.directive("highlight", function(el) {
  let blocks = el.querySelectorAll("pre code");
  blocks.forEach(block => {
    hljs.highlightBlock(block);
  });
});
// 增加组定义属性，用于在代码中预处理代码格式
Vue.prototype.$hljs = hljs;

Vue.use(JsonViewer);
Vue.use(MvFullPage)
Vue.use(ElementUI);

Vue.prototype.$Http = Http
Vue.prototype.$message = resetMessage
Vue.config.productionTip = false

Vue.component('footerModule', footerModule)

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
