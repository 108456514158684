import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  routes: [{
    path: "/index",
    name: "index",
    component: () => import('../pages/index/index.vue')
  },{
    path: "/api",
    name: "api",
    component: () => import('../pages/api/index.vue')
  },{
    path: "/log",
    name: "log",
    component: () => import('../pages/log/index.vue')
  },{
    path: "/code",
    name: "code",
    component: () => import('../pages/code/index.vue')
  },{
    path: "/video",
    name: "video",
    component: () => import('../pages/video/index.vue')
  },{
    path: "/scene",
    name: "scene",
    component: () => import('../pages/scene/index.vue')
  },{
    path: "/develop",
    name: "develop",
    component: () => import('../pages/develop/index.vue')
  },{
    path: "/device",
    name: "device",
    component: () => import('../pages/device/index.vue')
  },{
    path: "/tool",
    name: "tool",
    component: () => import('../pages/tool/index.vue')
  },{
    path: "/success",
    name: "success",
    component: () => import('../pages/success/index.vue')
  },{
    path: '*',
    redirect: '/index'
  }]
})

export default router
