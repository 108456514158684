import Vue from 'vue'

const shareHttp = `${window.location.origin}/czzy/indexB.html#/`

// 复制
export const copyFun = (text, isNeedHttp = true) => {
    const input = document.createElement("textarea");
    input.value = isNeedHttp ? shareHttp + text : text;
    document.body.appendChild(input);
    input.select();
    document.execCommand("Copy");
    document.body.removeChild(input);
    Vue.prototype.$message.success('复制成功');
}

// 获取url的域名或者ip
export const extractDomainOrIP = (url) => {
    // 匹配IP地址
    const ipPattern = /\b(?:\d{1,3}\.){3}\d{1,3}\b/;

    // 匹配域名
    const domainPattern = /[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)+/;

    // 提取IP地址
    const ipMatch = url.match(ipPattern);
    if (ipMatch) {
        return ipMatch[0];
    }

    // 提取域名
    const domainMatch = url.match(domainPattern);
    if (domainMatch) {
        return domainMatch[0];
    }

    return null; // 如果没有匹配到IP地址或域名，则返回null
}

// 获取url参数
export const getUrlParams = (url) => {
    const params = {};

    // 获取查询字符串部分
    const queryString = url.split('?')[1];

    // 检查是否存在查询字符串
    if (queryString) {
        // 拆分查询字符串为参数对
        const paramPairs = queryString.split('&');

        // 遍历参数对
        paramPairs.forEach(pair => {
            const [key, value] = pair.split('=');

            // 解码URL编码的值
            let decodedValue = decodeURIComponent(value);
            if (decodedValue.indexOf('#/') > -1) {
                decodedValue = decodedValue.substring(0, decodedValue.length - 2)
            }

            // 将参数添加到JSON对象中
            params[key] = decodedValue;
        });
    }

    return params;
}

// 判断时间在哪个时间段
export const getTimeState = () => {
    // 获取当前时间
    let timeNow = new Date();
    // 获取当前小时
    let hours = timeNow.getHours();
    // 设置默认文字
    let text = ``;
    // 判断当前时间段
    if (hours >= 0 && hours <= 10) {
        text = `早上好！`;
    } else if (hours > 10 && hours <= 14) {
        text = `中午好！`;
    } else if (hours > 14 && hours <= 18) {
        text = `下午好！`;
    } else if (hours > 18 && hours <= 24) {
        text = `晚上好！`;
    }
    // 返回当前时间段对应的状态
    return text;
};
