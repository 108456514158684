<template>
  <div id="app">
    <div :class="['header', theme === 'dark' && 'light-dark']" v-if="!noHeaderUrl.includes(currentUrl)">
      <div class="header-l">
        <img :src="cfg.logo ? cfg.logo : require('./static/logo.png')" alt="/" class="logo" @click="handleGo('/index')" />
        <div class="nav">
          <div v-for="(item, index) in listNav" :key="index" :class="['item-nav', current == index && 'item-act']" @click="handleGo(item.url)">{{item.name}}</div>
        </div>
      </div>
      <div class="header-r" v-if="cfg.radio == 1">
        <div class="user-info" v-if="userInfo">
          <span>{{userInfo.nickname}}</span>
          <el-image style="width: 40px; height: 40px" :src="userInfo.headimgurl"></el-image>
        </div>
        <el-popover width="300" trigger="hover" style="margin-left: 10px;" v-else-if="!userInfo && isOurWeb">
          <wxlogin :appid="appid" scope="snsapi_login" :redirect_uri="redirect_uri"></wxlogin>
          <div class="weixin-info" slot="reference">
            <img src="./static/login.png" />
          </div>
        </el-popover>
        <div class="weixin-info" slot="reference" v-else>
          <img src="./static/login.png" @click="goLogin" />
        </div>
      </div>
    </div>
    <keep-alive include="apiIndex, sceneIndex">
      <router-view @onHeaderChange="onHeaderChange" @onHandleShow="onHandleShow" />
    </keep-alive>

    <card-moudle :cardShow="cardShow" :cardIndex="cardIndex" :cardData="cardData" />
    <cfg-moudle />
  </div>
</template>

<script>
// ?code=091YXZkl2IJCab4PvGkl254uZc1YXZkH&state=
import wxlogin from "vue-wxlogin";
import cardMoudle from "./components/card.vue"
import cfgMoudle from "./components/cfg.vue"
import { extractDomainOrIP, getUrlParams } from "@/utils/common"
import { creatSSE } from "@/utils/sse"
export default {
  name: 'App',
  components:{
    wxlogin,
    cardMoudle,
    cfgMoudle
  },
  data() {
    return {
      isOurWeb: false,
      userInfo: null,
      appid: 'wxfd03ade7e5d0299e',
      redirect_uri: encodeURIComponent("https://open.31gps.net/#/api"),
      theme: 'light',
      current: 0,
      lists: [
        {name: '首页', url: '/index'},
        {name: '接口文档', url: '/api'},
        // {name: '开发指南', url: '/develop'},
        {name: '设备扩展', url: '/device'},
        // {name: '更新日志', url: '/log'},
        {name: '代码开源', url: '/code'},
        {name: '视频讲座', url: '/video'},
        {name: '场景分析', url: '/scene'},
        {name: '三方工具', url: '/tool'},
        // {name: '配置', url: '/cfg'}
      ],
      // 不需要标题头部
      currentUrl: '/cfg',
      noHeaderUrl: ['/cfg', '/success'],
      cfg: {},
      cardShow: false,
      cardIndex: 0,
      cardData: {}
    }
  },
  computed: {
    listNav() {
      if(this.cfg.checkedLists) {
        const result = this.lists.map(item => {
          if(this.cfg.checkedLists.includes(item.url)) {
            return item
          }
        }).filter(item => !!item)
        return result
      } else {
        return this.lists
      }
    }
  },
  provide() {
    return {
      cfgData: () => this.cfg
    }
  },
  watch: {
    '$route': {
      handler(val) {
        const { path } = val
        this.currentUrl = path
        this.current = this.lists.findIndex(list => list.url === path)
        // 监听用户是否授权
        const code = this.getUrlCode(location.hash);
        if(code) {
          if(localStorage.getItem('userInfo')) {
            this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
          } else {
            this.getWeChatAuth(code)
          }
        }
      },
      deep: true
    }
  },
  mounted() {
    if(location.host === 'open.31gps.net') {
      this.isOurWeb = true
    }
    if(localStorage.getItem('userInfo')) {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    }
    // if：用户通过其它页面打开的tab；else：用户直接在浏览器输入url
    const extractResult = extractDomainOrIP(document.referrer)
    if(extractResult) {
      const params = getUrlParams(document.location.href)
      let sessionId = params.sessionId || ''
      localStorage.setItem('sessionId', sessionId)
      localStorage.setItem('extractIp', extractResult)
      // if：用户已经重定向；else：用户没有重定向
      if(params.noHref == 1) {
        this.getCfg(location.hostname)
      } else {
        this.getCfg(extractResult, 'tab', sessionId, extractResult)
      }
    } else {
      this.getCfg(location.hostname)
      // this.getCfg('open.31gps.net')
    }
  },
  methods: {
    goLogin() {
      creatSSE(() => {
        window.open(`https://open.weixin.qq.com/connect/qrconnect?appid=wxfd03ade7e5d0299e&scope=snsapi_login&redirect_uri=https://open.31gps.net/canvas/success.html&state=0001&login_type=jssdk&self_redirect=default&style=black&href=./wx.css`, '_block')
      }, (e) => {
        try{
          this.userInfo = JSON.parse(e.data)
          localStorage.setItem('userInfo', e.data)
        } catch(e) {
          console.log(e)
        }
      })
    },
    onHeaderChange(val) {
      this.theme = val
    },
    handleGo(url) {
      this.$router.push({
        path: url
      })
    },
    onHandleShow(index, data) {
      setTimeout(() => {
        this.cardShow = true
        this.cardIndex = index
        this.cardData = data
      }, 300)
    },
    async getCfg(url, str, sessionId, ip) {
      const ret = await this.$Http('GET', { url }, '/api/apiCfg/getCfgByUrl');
      if(str === 'tab' && ret.url) {
        window.location.href = `http://${ret.url}#/index?sessionId=${sessionId}&ip=${ip}&noHref=1`
      } else {
        if(ret.exts) {
          this.cfg = JSON.parse(ret.exts)
          this.cfg.url = ret.url
        }
      }
      document.title = this.cfg.companyName || '三一平台.二次开发'
    },
    async getWeChatAuth(code) {
      const ret = await this.$Http('GET', { code }, '/api/apiDoc/getWxUserInfo');
      if(!ret.openid) {
        this.$message.warning("授权失败")
        localStorage.removeItem('userInfo')
      } else {
        const info = {
          headimgurl: ret.headimgurl,
          nickname: ret.nickname,
          openid: ret.openid
        }
        this.userInfo = info
        localStorage.setItem('userInfo', JSON.stringify(info))
      }
    },
    // 获取code
    getUrlCode(str) {
      if(str.indexOf('code=') == -1) return false;
      const s = str.indexOf('code=') + 5
      const e = str.indexOf('&state')
      return str.substring(s, e)
    }
  }
}
</script>

<style src="../assets/common.css"></style>
<style scoped>
ul{
  list-style: inherit;
}
</style>
<style scoped>
.header{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 90px;
  padding-left: 39px;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  background: rgba(57, 57, 57, 0);
  transition: all 0.3s ease;
}
.header-l{
  display: flex;
  align-items: center;
}
.header-r{
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 20px;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
}
.user-info{
  display: flex;
  align-items: center;
}
.user-info span{
  white-space: nowrap;
  margin-right: 20px;
}
.weixin-info img{
  width: 40px;
}
.light-dark{
  background: rgba(57, 57, 57, 1);
}
.logo{
  width: 250px;
  height: 60px;
  cursor: pointer;
}
.nav{
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #fff;
}
.item-nav{
  white-space: nowrap;
  margin-left: 60px;
  cursor: pointer;
}
.item-nav:hover{
  color: #4289DA;
}
.item-act{
  color: #4289DA;
  font-weight: bold;
}
</style>
