import axios from 'axios'
import { Message } from 'element-ui'

// let instance = axios.creat({
//     baseURL: 'http://192.168.2.75:10041',
//     timeout: 10000
// })

// http://192.168.2.193:10040

// export const baseUrl = 'http://192.168.2.193:10040'
// export const baseUrl = 'http://192.168.2.75:10041'
export const baseUrl = 'https://zs.31gps.net:8888'

const http = (method='GET', params, url, isHandle = true, isBlob = false) =>{
    // const allUrl = 'https://zs.31gps.net:8888' + url
    // const allUrl = 'http://192.168.2.193:10040' + url
    const allUrl = baseUrl + url
    

    /* 发送请求 */
    return new Promise((resolve,reject) => {
        axios({
            url: allUrl,
            method,
            headers: {
                'Content-Type': method === 'GET' ? 'application/json' : 'application/x-www-form-urlencoded'
            },
            data:method != "GET" ? params : null,
            responseType: isBlob ? 'blob' : '',
            params:method == "GET" ? params : null
        }).then(result => {
            if(isHandle) {
                if(result.data.status == 0) {
                    Message.error(result.data.result)
                    reject(result.data)
                }
                if(result.data.result) {
                    resolve(result.data.result)
                }else{
                    resolve(result.data)
                }
            } else {
                resolve(result)
            }
        })
        .catch(error => {
            reject(error)
        })
    })
}

export default http